<template>
  <MainLayout>
    <v-card class="ma-5">
      <v-card-title>
        <v-icon>mdi-view-list</v-icon>
        Quick
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <img class="fit-img"
                 :src="images.original"
                 alt="Original"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <img class="fit-img"
                 :src="images.graffiti"
                 alt="Graffiti"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex';
import MainLayout from '../../components/Layouts/MainLayout.vue';

export default {
  name: 'QuickPage',
  components: {
    MainLayout,
  },

  data() {
    return {
      images: {
        graffiti: null,
        original: null,
      },
    };
  },

  created() {
    this.images.graffiti = this.getImageByUrl('graffiti');
    this.images.original = this.getImageByUrl('original');
  },

  methods: {
    ...mapActions('resources', ['getQuickImageFromApi']),
    getImages(type) {
      return this.getQuickImageFromApi({
        quickId: this.$route.params.quickId,
        imageType: type,
      }).then((r) => {
        console.log('A');
        return `data:image/png;base64,${r.data}`;
      });
    },
    getImageByUrl(type) {
      return `http://dev-graffiti.dotspot.at:12061/quick/file/image/${this.$route.params.quickId}/${type}`;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
